import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../../components/layout/Layout.component";
import Author from "../../../components/author/Author.component";
import * as style from "../Project.style.module.scss";

const CondensaPump = () => {
  return (
    <Layout>
      <StaticImage
        src="./images/markus-spiske-1LLh8k2_YFk-unsplash.jpg"
        as="header"
        className={style.hero}
      />
      <main className={style.post}>
        <article className={style.article}>
          <div className={style.date}>Project</div>
          <h1>Condensa heat pumps</h1>
          <h2>Introduction</h2>
          <p>
            This side was also a big stylistic challenge. The final and initial
            design differ quite significantly. It was also the first time I
            heard that the site is too pretty. The client said that the photos
            are too pretty and do not represent the real houses. In addition,
            the website is not homely. The design was also constantly changing
            due to changes in the business. Changes in electricity prices, no
            availability of heat pumps. We had to dynamically change the design
            for a strange business situation. Even the client's area of activity
            has changed. All these factors strongly influenced the final shape
            of the website.
          </p>
          <h2>Sketch</h2>
          <StaticImage src="./images/condensa_pump.png" as="div" />
          <h2>Result</h2>
          <StaticImage src="./images/screenshot_condensa_pump.png" as="div" />
        </article>
        <aside>
          <Author />
        </aside>
      </main>
    </Layout>
  );
};
export default CondensaPump;

export function Head() {
  return <title>Condensa Heat Pumps</title>;
}
